import React from "react"
import { Title, Gap, Row, Col, Text, Link, SEO } from "../components"
import injectIntl from "../lib/intl/injectIntl"
import T from "../lib/intl/T"

class Page404 extends React.Component {
  render() {
    const {
      intl: { language, t },
    } = this.props

    return (
      <>
        <SEO title={t("404 - meta title")} />
        <Col maxWidth="500px" alignSelf="center">
          <Gap gap="150px" />
          <Title.PageTitle>404</Title.PageTitle>
          <Gap gap="24px" />
          <Title.SmallTitle gray>
            <T>Stránka nebyla nalezena</T>
          </Title.SmallTitle>
          <Gap gap="24px" />

          <Col alignItems="center">
            <Gap gap="16px" />
            <Link.BasicLink to={Link.HOMEPAGE}>
              <T>Přejít na FORMÉ clinic</T>
            </Link.BasicLink>
            <Gap gap="150px" />
          </Col>
        </Col>
      </>
    )
  }
}

export default injectIntl(Page404)
// export default Page404
